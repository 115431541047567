import * as ScrollArea from "@radix-ui/react-scroll-area";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, theme } from "antd";
import { use8k } from "./use8k";
import { appContext } from "./useApp";
import { useContext, useEffect } from "react";

export function DocPanel() {
  const { token } = theme.useToken();
  const context = useContext(appContext)
  const { doc } = context

  const hasDoc = Boolean(doc);
  return hasDoc ? (
    <DocViewerPanel />
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}


const locateElem = (location: string) => {
  const $el = document.querySelector("#document-html"); // your root
  if (!$el) return
  if (!location) return
  if (location.includes("_")) {
    const [tag, idx] = location.split("_")
    if (Number(idx) === 0) return
    const elems = $el.querySelectorAll(tag)
    return elems[Number(idx) - 1]
  } else if (location.startsWith("T")) {
    const idx = Number(location.slice(1)) - 1 // locator is 1-indexed
    const tables = $el.querySelectorAll('table')
    return tables[idx]
  }
}

function DocViewerPanel() {
  const { token } = theme.useToken();
  const context = useContext(appContext)
  const { doc } = context
  if (!doc) throw new Error("Expected doc");
  const viewer = use8k(doc!)

  // ------------------ highlight locations
  const highlights = context.highlights
  useEffect(() => {

    // remove previous highlights
    document.querySelectorAll(".highlight").forEach(el => el.classList.remove("highlight"))

    if (highlights.length === 0) return
    const allElems = []
    for (const highlight of highlights){
      const startElem = locateElem(highlight.startLocator)
      const endElem = locateElem(highlight.endLocator)
      const elems = minimalNodesBetween(startElem!, endElem!)
      allElems.push(...elems)
    }

    allElems.forEach(node => {
      if (node instanceof HTMLElement) {
        node.classList.add("highlight")
      }
    })
  }, [highlights])


  //------------- scroll to location
  const show = (location: string| undefined) => {
    if (!location) return
    const elem = locateElem(location)
    if (elem){
      if (elem instanceof HTMLElement){
        elem.scrollIntoView({block: "center", behavior: "smooth"})
      }
    }
  }

  useEffect(() => {
    if (context.focused){
      show(context.focused.locator)
    }
  },[viewer.ready, context.focused])


// ------------------ frame a location
const frame = (location: string | undefined) => {
  if (!location) return
  const elem = locateElem(location)
  if (elem){
    // check if node is HTMLElement
    if (elem instanceof HTMLElement) {
      elem.classList.add("hoverborder");
    } else if (elem instanceof Text) {

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(elem);
      if (selection){
        // selection.removeAllRanges();
        selection.addRange(range);
      }
      
    }

  }
}


  useEffect(() => {
    const $el = document.querySelector("#document-html"); // your root
    if (!$el) return;
    $el
    .querySelectorAll(".hoverborder")
    .forEach((el) => el.classList.remove("hoverborder"));
    const locations = context.hovered
    if (!locations || locations.length === 0) return
    for (const location of locations){
      frame(location.locator)
    }
    show(locations.at(0)!.locator)

  }, [viewer.ready, context.hovered]);


  if (!viewer.ready)
    return (
      <Flex justify="center" align="center" style={{ height: "100%" }}>
        <LoadingOutlined />
      </Flex>
    );

  return (
    <Flex
      vertical
      style={{
        height: "100%",
        border: `solid 1px ${token.colorBgContainer}`,
        borderRadius: 15,
        boxShadow: token.boxShadowTertiary,
      }}
    >

      <div
        style={{
          overflow: "hidden",
          height: "100%",
          backgroundColor: token.colorBgContainer,
          borderRadius: "0 0 15px 15px",
        }}
      >
        <ScrollArea.Root className="ScrollAreaRoot">
          <ScrollArea.Viewport className="ScrollAreaViewport">
            <div id="document-html"
              style={{
                padding: 20,
              }}
              dangerouslySetInnerHTML={{ __html: viewer.html }}
            />
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </div>
    </Flex>
  );
}

const minimalNodesBetween = (startElem: Element, endElem: Element) => {
  if (!startElem){
    return []
  }
  const range = document.createRange();
  range.setStart(startElem!, 0);
  range.setEnd(endElem!, 0);
  const ancestor = range.commonAncestorContainer
  const upTo = (elem: Element) => {
    const res = []
    let current = elem
    while (current.parentElement && current !== ancestor) {
      current = current.parentElement
      res.push(current)
    }
    return res
  }
  const endAncestors = upTo(endElem!)
  const elems = [endElem!]
  let current = startElem!
  while (true) {
    if (current === endElem) break
    elems.push(current)
    let found = false
    while (current.nextSibling) {
      const sibling = current.nextSibling
      if (endAncestors.includes(sibling as Element)) {
        found = true
        current = sibling as Element
        do {
          current = sibling.firstChild as Element
        } while (endAncestors.includes(current))
        break
      } else {
        elems.push(sibling as Element)
      }
    }
    if (!found) {
      current = current.parentElement!
    }
  }
  return elems
}