import { useSearchParams } from "react-router-dom";
import { createContext, useState } from "react";


export type UseApp = ReturnType<typeof useApp>
export const appContext = createContext(undefined as unknown as UseApp)

export type Scope = {
    tickers: string[],
    since: string,
    until: string | undefined,
    requestedAtHour: string
}

export function useApp() {
    let [searchParams] = useSearchParams();
    const [highlights, setHighlights] = useState<{ startLocator: string, endLocator: string }[]>([])
    const [focused, setFocus] = useState<{locator:string} | undefined>(undefined)
    const [hovered, setHover] = useState<{locator:string}[]>([])
    
    const ticker = searchParams.get("ticker")
    const accessionNumber = searchParams.get("accessionNumber")
    const doc = ticker && accessionNumber ? { ticker, accessionNumber } : undefined


    return {
        doc,
        setHighlights,
        highlights,
        focused,
        setFocus,
        hovered,
        setHover,
    }
}