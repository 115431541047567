import * as ScrollArea from "@radix-ui/react-scroll-area";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, theme } from "antd";
import { useContext, useEffect, useMemo } from "react";
import { chatContext } from "./useChatApp";
import { useDocSearch } from "./useDocSearch";
import { useDocViewer } from "./useDocViewer";

export function DocPanel() {
  const { token } = theme.useToken();
  const context = useContext(chatContext);
  const hasDoc = Boolean(context.current.doc);
  return hasDoc ? (
    <DocViewerPanel />
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}

function DocViewerPanel() {
  const { token } = theme.useToken();
  const context = useContext(chatContext);
  if (!context.current.doc) throw new Error("Expected doc");

  const { lines: highlight } = useDocSearch(context.current.doc);
  const viewer = useDocViewer(context.current.doc);

  const lines = useMemo(() => {
    if (highlight.length > 0) {
      return highlight.map((line) => line.text.trim());
    }
    return [];
  }, [highlight]);

  useEffect(() => {
    const $el = document.querySelector("#document-html");
    if (!$el || !lines.length) return;
    $el
      .querySelectorAll(".highlight")
      .forEach((el) => el.classList.remove("highlight"));
    let element: Element | null = null;
    for (const line of lines) {
      element = findElementByText($el, line);
      if (element) break;
    }
    if (element) {
      (element as HTMLElement).classList.add("highlight");
      element?.scrollIntoView();
    } else {
      getScrollParent($el as HTMLElement)?.scrollTo(0, 0);
    }
  }, [lines]);

  if (!viewer.ready)
    return (
      <Flex justify="center" align="center" style={{ height: "100%" }}>
        <LoadingOutlined />
      </Flex>
    );

  return (
    <Flex
      vertical
      style={{
        height: "100%",
        border: `solid 1px ${token.colorBgContainer}`,
        borderRadius: 15,
        boxShadow: token.boxShadowTertiary,
      }}
    >
      <div
        style={{
          overflow: "hidden",
          height: "100%",
          backgroundColor: token.colorBgContainer,
          borderRadius: 15,
          paddingTop: 10,
        }}
      >
        <ScrollArea.Root className="ScrollAreaRoot">
          <ScrollArea.Viewport className="ScrollAreaViewport">
            <div
              id="document-html"
              style={{
                padding: 20,
              }}
              dangerouslySetInnerHTML={{ __html: viewer.html }}
            />
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </div>
    </Flex>
  );
}

function findElementByText(
  container: Element,
  searchText: String
): Element | null {
  const elements = [...container.querySelectorAll("*")] as Element[];
  let foundElement = null;
  for (const element of elements) {
    if (element.textContent?.toLowerCase().includes(searchText.toLowerCase())) {
      foundElement = element;
      break;
    }

    if (element.children.length > 0) {
      const innerFound = findElementByText(element, searchText);
      if (innerFound) {
        foundElement = innerFound;
        break;
      }
    }
  }

  return foundElement;
}

function getScrollParent(node: HTMLElement | null): HTMLElement | undefined {
  if (!node) return undefined;
  if (node.scrollHeight > node.clientHeight) return node;
  return getScrollParent(node.parentNode as HTMLElement);
}
