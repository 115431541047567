import { post } from "./lib";

type Location = {from:number, to: number, locator: string}
export type DataItem = {
  Amount: string
  Name: string
  ReferenceText: string,
  Description:string,
  Year: string
  mapping?: string
  parentItem?: {relation: string, item: DataItem}
}
type Footnote = {
  reference: string,
  content: string,
  locations: Location[]  
}

export type FootnoteResult = Footnote & {
  dataItems: DataItem[]
  kind: string
  tables?: TableResult[]
}
export type TableResult = {
  location: Location
  footnotes: Footnote[]
  mapping?: Record<string,string>
  parsed: {
    headers: {value: string}[],
    datarows: Record<string, {value:string}>[]
  }
  parsedFootnotes?: FootnoteResult[]
}

type SectionCategory = string
type Chunk = {
  type: string, text:string, page: number
}
type Page = {idx: number, numberStr?: string, number?: number}
type CategorizedChunk = Chunk & {category?:SectionCategory}
export type DocMap = {
  toc: Toc
  chunks: CategorizedChunk[],
  pages: Page[],
  sections: Record<SectionCategory, Section[]>
  smoothedSections: Record<SectionCategory, Section[]>
  execSections: Record<SectionCategory, Section[]>
}

type Section = {
    category: SectionCategory,
    locators: string[],
    title: string,
    pages: string[],
}

type Result = {
  sct: TableResult,
  otherComp: {
    footnotes: FootnoteResult[]
    otherContent: {
      items: DataItem[]
      table?: TableResult
    }
  },
  docMap: DocMap
  evaluation: {
    column: string,
    ticker: string,
    officerName: string,
    fiscalYear: string,
    output: string | number  | undefined
    truth: string | number
    result: "match" | "no match" | "no data"
  }[][],
  toc: Toc,
  dataItems: DataItem[]

}

type Toc = {
  title: string,
  locators: string[],
  category: string,
  pages: string[],
  chapters: {
    title: string,
    category: string,
    pages: string[],
    locators: string[]
  }[]
}[]

export function processdef14a(query: {
  ticker: string;
  year: number;
  form: string;
}): Promise<Result> {
  return post(`/api/def14a/process.json`, query);
}

