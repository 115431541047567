import { useContext } from "react";
import { appContext } from "./useApp";
import { Empty, Flex, Table, theme } from "antd";
import { use8k } from "./use8k";
import { LoadingOutlined } from "@ant-design/icons";
import { CompensationItem, PositionChange } from "../../api-clients/8k_502";
import { useEffect } from "react";
export function ResultPanel() {
    const context = useContext(appContext)
    const hasDoc = Boolean(context.doc)
    const { token } = theme.useToken();
    return hasDoc ? (
        <ResultViewerPanel />
    ) : (
        <Flex
            align="center"
            justify="center"
            style={{
                height: "100%",
                backgroundColor: `${token.colorBgLayout}99`,
            }}
        >
            <Empty description="No filing selected" />
        </Flex>
    );
}

const ResultViewerPanel = () => {
    const context = useContext(appContext)
    const {setHighlights, setFocus} = context
    const { token } = theme.useToken();

    const { ready, data } = use8k(context.doc);

    useEffect(() => {
        const allLocations = (data ?? []).flatMap(d => [
            d.ReferenceLocation,
                ...(d.CompensationItems ?? []).map(b => b.ReferenceLocation),
            ].filter(Boolean));
            if (allLocations.length > 0){
            const toHighlight = allLocations.map(l => ({startLocator: l!.startLocator!, endLocator: l!.endLocator!}))
            setHighlights(toHighlight)
            setFocus({locator: toHighlight[0].startLocator})
        }
    }, [data, setHighlights, setFocus])

    if (!ready || !data) return (
        <div style={{ paddingBottom: 10 }}>
            <LoadingOutlined />
        </div>
    )
    const datasource = (data || []).map(d => {
        return { ...d, Position: d.Positions.join(", ") }
    })


    const columns = [
        {
            title: "Name",
            dataIndex: "Name",
            key: "Name",
        },
        {
            title: "Change",
            dataIndex: "Change",
            key: "Change",
        },
        {
            title: "Position",
            dataIndex: "Position",
            key: "Position",
        },
        {
            title: "EffectiveDate",
            dataIndex: "EffectiveDate",
            key: "EffectiveDate",
        },
    ]

    return (
        <>
            <div
                style={{
                    display: "flex",
                    height: "100%",
                    overflow: "hidden",
                    backgroundColor: token.colorBgLayout,
                }}
            >
                <Table
                    dataSource={datasource}
                    columns={columns}
                    onChange={(pagination, filters, sorter) => {
                        console.log("Various parameters", pagination, filters, sorter);
                        //   setFilteredInfo(filters);
                        //   setSortedInfo(sorter);
                    }}
                    pagination={{
                        pageSize: 15, // Set the default pageSize to 20
                        total: datasource.length, // Total number of items
                    }}
                    onRow={(record) => ({
                        onClick: () => { 
                            const location = record.ReferenceLocation
                            if (location){
                                context.setFocus({locator: location.startLocator!})
                                context.setHover([{locator: location.startLocator!}])
                            }
                        },
                        onMouseEnter: () => {
                            const location = record.ReferenceLocation
                            if (location){
                                context.setHover([{locator: location.startLocator!}])
                            }
                        }
                    })}
                    expandable={{
                        defaultExpandAllRows: true,
                        expandedRowRender: (record) => (
                            <>
                                <CompensationTable {...record} />
                            </>
                        )
                    }}
                />
            </div>
        </>
    );
}

const CompensationTable = (record: PositionChange) => {
    const context = useContext(appContext)

    const columns = [
        {
            title: "Name",
            dataIndex: "Name",
            key: "Name",
        }, {
            title: "Amount",
            dataIndex: "Amount",
            key: "Amount",
        },
        {
            title: "Unit",
            dataIndex: "Unit",
            key: "Unit",
        },
    ]
    const asRow = (name: string, idx: number, item: CompensationItem) => {
        const Name = idx > 0 ? `${name} ${idx}` : name
        return {
            Name,
            Amount: item.Amount,
            Unit: item.Unit,
            Period: item.Period,
            ReferenceLocation: item.ReferenceLocation
        }
    }
    const groupedByKind = (record.CompensationItems ?? []).reduce((acc, c) => {
        acc[c.Kind] = acc[c.Kind] || []
        acc[c.Kind].push(c)
        return acc
    }, {} as Record<string, CompensationItem[]>)

    const items = Object.values(groupedByKind).flatMap(items => 
        items.map((item, idx) => asRow(item.Kind, idx, item))
    )

    return (
        <Table 
        dataSource={items} 
        columns={columns} 
        pagination={false}
         showHeader={false}
         onRow={(record) => ({
            onMouseEnter: () => {
                const location = record.ReferenceLocation
                if (location){
                    context.setHover([{locator: location.startLocator!}])
                }
            }
         })}

          />
    )
}